<template>
  <v-dialog v-model="dialog" width="600" @keydown.esc="close" persistent>
    <template v-slot:activator="{ on }">
      <div v-on.stop="on" @click.stop v-on="on">
        <slot name="activator"></slot>
      </div>
    </template>
    <v-card v-if="dialog" class="secondary-background">
      <v-card-title class="headline py-3" primary-title>
        <span>{{
          initiative.id ? "Editar Iniciativa" : "Nova Iniciativa"
        }}</span>

        <v-spacer></v-spacer>

        <v-btn text icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="mt-5">
        <v-form ref="form">
          <v-text-field
            v-model="initiative.name"
            label="Nome*"
            :rules="nameRules"
            name="name"
            required
          ></v-text-field>
          <v-text-field
            v-model="initiative.key"
            label="Chave*"
            name="key"
            @change="keyEdited = true"
            :rules="keyRules"
          ></v-text-field>
          <v-text-field
            v-model="initiative.description"
            label="Descrição"
            name="description"
          ></v-text-field>
          <v-checkbox
            v-model="initiative.used_only_by_users"
            label="Utilizada somente por usuários"
          ></v-checkbox>
          <h3 class="mt-2">Cabeçalho</h3>
          <v-select
            :items="headerTypes"
            item-text="name"
            item-value="value"
            v-model="initiative.header_type"
            label="Tipo de cabeçalho"
            name="header_type"
          ></v-select>
          <v-textarea
            v-model="initiative.header_text"
            label="Texto do cabeçalho"
            counter="60"
            :maxlength="60"
            name="header_text"
            rows="1"
          >
          </v-textarea>
          <h3 class="mt-1">Corpo</h3>
          <v-textarea
            v-model="initiative.message"
            label="Mensagem*"
            name="message"
            counter="1024"
            :maxlength="1024"
            :rules="messageRules"
            rows="3"
          >
          </v-textarea>
          <v-textarea
            v-model="initiative.template"
            label="Template da infobip*"
            name="template"
            counter="1024"
            :maxlength="1024"
            :rules="messageRules"
            rows="3"
          >
          </v-textarea>
          <v-textarea
            v-model="initiative.footer_text"
            label="Texto do footer*"
            counter="60"
            :maxlength="60"
            name="footer_text"
            rows="1"
          >
          </v-textarea>
          <h3 class="mt-1">Botões</h3>
          <v-select
            :items="buttonsType"
            item-text="name"
            item-value="value"
            v-model="initiative.button_type"
            label="Tipo de footer*"
            name="button_type"
            @change="
              checkButtonType(initiative.button_type);
            "
          ></v-select>
          <QuickReplyButtons  v-if="initiative.button_type == 'QUICK_REPLY'" ref="QuickReplyButtons" :initiative="initiative"> </QuickReplyButtons>
          <div v-if="initiative.button_type == 'CALL_TO_ACTION'">
            <div class="d-flex">
              <v-text-field
                :maxlength="20"
                counter="20"
                class="mr-2"
                label="Título"
                prepend-icon="phone"
                v-model="initiative.buttons.number.label"
              ></v-text-field>
              <v-text-field
                label="Valor"
                v-model="initiative.buttons.number.value"
              ></v-text-field>
            </div>
            <div class="d-flex">
              <v-text-field
                :maxlength="20"
                counter="20"
                class="mr-2"
                label="Título"
                prepend-icon="open_in_new"
                v-model="initiative.buttons.link.label"
              ></v-text-field>
              <v-text-field
                label="Valor"
                v-model="initiative.buttons.link.value"
              ></v-text-field>
            </div>
          </div>
          <!-- <div v-if="initiative.vars">
            <div v-for="var_item in initiative.vars" :key="var_item">
              <v-text-field
                required
                v-model="initiative.sample[var_item]"
                :label="var_item"
                :rules="[v => !!v || 'Este campo é obrigatório']"
              ></v-text-field>
                @keydown="valueChanged(index,field)"
            </div>
          </div> -->
        </v-form>

        <!-- <InitiativeExample :initiative="initiative">
        </InitiativeExample> -->
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">Cancelar</v-btn>
        <v-btn color="primary" text @click="save(initiative)">
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import QuickReplyButtons from './FormComponents/QuickReplyButtons'

import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    QuickReplyButtons,
  },
  data() {
    return {
      keyMask: /^[a-zA-Z0-9_]+$/,
      dialog: false,
      teste: "",
      originalKey: "",
      keyEdited: false,
      keyValidation: false,
      requesting: false,
      initiatives: [],
      initiative: {},
      used_only_by_users: false,
      service_id: this.$route.params.service_id,
      isValidButton: [],
      actionsList: [
        {
          link: {
            label: "Minha Proposta",
            value: "",
          },
        },
        {
          number: {
            label: "Ligue-nos",
            value: "",
          },
        },
      ],
      headerTypes: [
        { value: null, name: "Nenhum" },
        { value: "DOCUMENT", name: "Documento" },
        { value: "IMAGE", name: "Imagem" },
        { value: "VIDEO", name: "Video" },
      ],
      buttonsType: [
        { value: null, name: "Nenhum" },
        { value: "QUICK_REPLY", name: "Resposta Rápida" },
        { value: "CALL_TO_ACTION", name: "Chamada Para Ação" },
      ],
      // VALIDAÇÔES
      nameRules: [
        (v) => !!v || "Nome em branco",
        (v) => (v && v.length < 256) || "Nome contem muitos caracteres",
      ],
      keyRules: [
        (v) => !!v || "Chave em branco",
        (v) => (v && v.length < 256) || "Chave contem muitos caracteres",
        (v) =>
          this.keyMask.test(v) ||
          "Apenas underscore (sublinhado) é permitido como caractere especial",
      ],
      messageRules: [
        (v) => !!v || "Mensagem em branco",
        (v) => (v && v.length < 1024) || "Mensagem contem muitos caracteres",
      ],
    };
  },
  mounted() {},
  methods: {
    ...mapActions("rasaInitiative", {
      saveInitiative: "save",
    }),
    checkLength(item) {
      if (item > 0) {
        return true;
      } else {
        return false;
      }
    },
    close() {
      this.dialog = false;
    },
    save(initiative) {
      if(this.initiative.button_type == 'QUICK_REPLY') {
        this.initiative.buttons = this.$refs.QuickReplyButtons.buttons
      }
      this.validate()
        .then(() => {
          this.saveInitiative(initiative).then(() => {
            this.dialog = false;
            this.$emit("tableEdited");
          });
        })
        .catch((err) => {});
    },
    validate() {
      return new Promise((resolve, reject) => {
        if (this.$refs.form.validate()) {
          resolve("resolvido");
        } else {
          reject("rejeitado");
        }
      });
    },
    checkButtonType(value) {
      if (value == null) {
        this.initiative.buttons = null;
      }
      // if (value == "QUICK_REPLY") {
      //   this.initiative.buttons = [];
      //   this.initiative.buttons[0] = {};
      //   Object.assign(this.initiative.buttons[0], {
      //     label: "",
      //     value: "",
      //   });
      //   this.initiative.buttons[1] = {
      //     label: "",
      //     value: "",
      //   };
      //   this.initiative.buttons[2] = {
      //     label: "",
      //     value: "",
      //   };
      // }
      if (value == "CALL_TO_ACTION") {
        this.initiative.buttons = {};
        this.initiative.buttons = {
          number: {
            value: "5541988887777",
          },
          link: {
            value: "https://negocie.quiteja.com.br/acordo?id=$acordo_id",
          },
        };
      }
    },
  },
  computed: {
    ...mapGetters('initiative', {
      template: 'template'
    }),
  }
};
</script>

<style lang="scss" scoped>
h3 {
  margin: 15px 0;
  width: 100%;
  color: #666;
  // border-bottom: 1px solid;
  text-align: left;
}
</style>