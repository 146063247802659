<template>
  <v-dialog
    content-class="modal-view"
    scrollable
    v-model="previewDialog"
    max-width="760px"
  >
    <v-card class="secondary-background">
      <v-card-text :class="`${this.$vuetify.theme.dark ? 'conversation-dark' : 'conversation-light'}`">
        <div class="triangle"></div>
        <div class="message-box" :class="`${this.$vuetify.theme.dark ? 'dark-box-message' : 'light-box-message'}`">
          <Header :selectedInitiative="selectedInitiative"> </Header>
          <Body
            class="message-body"
            :selectedInitiative="selectedInitiative"
          ></Body>
          <Footer
            class="message-footer"
            :selectedInitiative="selectedInitiative"
          >
          </Footer>
        </div>
        <Buttons
          ref="test"
          class="message-buttons"
          :filteredButtons="filteredButtons"
          :selectedInitiative="selectedInitiative"
        ></Buttons>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="previewDialog = false"
          >Fechar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Header from "./Header.vue";
import Body from "./Body.vue";
import Footer from "./Footer.vue";
import Buttons from "./Buttons.vue";
export default {
  components: {
    Header,
    Body,
    Footer,
    Buttons,
  },
  data() {
    return {
      previewDialog: false,
      selectedInitiative: {},
      filteredButtons: {},
    };
  },
  watch: {
    previewDialog() {
      console.log(this.selectedInitiative)
      if(this.previewDialog == false){
        this.$emit('closePreview')
      }
      if (this.selectedInitiative.button_type != null ) {
        let asArray = Object.entries(this.selectedInitiative.buttons);
        let filtered = asArray.filter((x) => x[1].label != "");
        this.filteredButtons = Object.fromEntries(filtered);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;1,300&display=swap");

.triangle {
  width: 0;
  height: 0;
  border-top: 20px solid;
  border-left: 20px solid transparent;
  position: absolute;
  top: 20px;
  left: 12px;
}
.message-box {
  background-color: whitesmoke;
  width: 299px;
  border-radius: 5px;
  padding: 8px;
  margin-top: 20px;
  .message-body {
    margin: 15px 0;
  }
}
.message-buttons {
  margin-top: 5px;
  width: 299px;
}
.wppBack {
  padding-top: 20px;
  background-image: url("/img/icons/whatsappBackGround.png");
}
.switch-container {
  padding: 0;
}
.modal-view {
  border-radius: 0.5em;
}
.conversation-light {
  background-repeat: repeat;
  background-image: url("/img/wppBg.png");
}

.conversation-dark {
  background-repeat: repeat;
  background-image: url("/img/wppBgDark.png");
}
.intent-list {
  max-height: -webkit-fill-available;
  height: auto;
  background: white;
  overflow: auto;
  height: 100%;
}
.template-qr {
  background: white;
}
.box-table {
  max-height: calc(100vh - 70px);
}
.header-intents {
  min-height: max-content;
  display: flex;
  justify-content: space-around;
  max-height: 3.6em;
  div {
    flex: 1;
  }
  .v-text-field {
    width: 75%;
  }
  .v-input--selection-controls {
    justify-content: flex-end !important;
  }
}
  .light-box-message {
      background-color: #d9fdd3 !important;
      background: #d9fdd3 !important;
    }

  .dark-box-message {
    background-color: #005c4b !important;
    background: #005c4b !important;
  }
.percent {
  font-size: 14px;
  font-weight: 500;
  .variation {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0.5px;
  }
  .greater {
    color: #4caf50;
  }
  .less {
    color: #f44336;
  }
  &.global-average {
    font-size: 16px;
    margin: auto;
    .variation {
      font-size: 10px;
      font-weight: 400;
      line-height: 10px;
      letter-spacing: 0.5px;
    }
  }
}
.show-delete-icon {
  opacity: 0%;
}
.dropItem {
  display: flex;
  background: #f5f5f5;
  margin: 0 2%;
  :hover .show-delete-icon {
    opacity: 100%;
  }
}
.v-list-context-menu {
  display: flex !important;
  flex-direction: column !important;
  min-width: 7.5em;
}
.v-menu__content {
  border-radius: 5%;
}
.v-list-context-menu .v-list-item {
  display: flex;
  justify-content: initial;
}
.v-list-item {
  padding: 0.5em 1em;
  font-size: initial;
}
.v-list-item:hover {
  background: #eaeaea;
  cursor: pointer;
}
.quick-replies-header {
  font-size: initial;
}
.buttonCancel {
  background: white !important;
  box-shadow: unset !important;
  :hover {
    box-shadow: unset !important;
  }
}
.buttonSubmit {
  background: #00e58d !important;
  color: #00606a !important;
  font-size: larger !important;
}
.v-btn--disabled {
  font-size: larger !important;
}
.buttonDelete {
  background: #f52525 !important;
  color: white !important;
  font-size: larger !important;
}
.fabButtons {
  color: white;
  font-size: large;
}
.addMessageSpan {
  padding-top: 0.2em;
}
.addMessageContainer {
  display: flex;
  justify-content: flex-end;
  button {
    box-shadow: unset !important;
    background-color: unset !important;
  }
  :hover {
    cursor: pointer;
  }
}
div.v-toolbar__extension {
  background-color: red;
}
.divider-QRs {
  margin: 1em 0;
}
.message {
  color: #505050;
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 85%;
  word-wrap: break-word;
}
.message.sent {
  background: #e1ffc7;
  border-radius: 5px 0px 5px 5px;
  display: flex;
  float: right;
  flex-direction: column;
  width: 100%;
  .text-container {
    width: 95%;
    display: flex;
    span {
      padding: 10px;
      max-width: 100%;
    }
  }
  .image-container {
    width: 5%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex-direction: column;
  }
  .message-container {
    flex: 1;
    display: flex;
  }
  .time-container {
    display: flex;
    justify-content: flex-end;
    span {
      font-style: italic;
      font-size: small;
    }
  }
}
.messages-container {
  display: block;
  align-items: center;
  background: #f9f9f9;
  padding: 1em;
  border-radius: 2em;
}
.v-card {
  width: 100%;
  overflow: auto;
  .intent-table {
    width: 100%;
    .clickable {
      cursor: pointer;
    }
    .has-unpublished {
      background-color: #cbfff4;
    }
    .active-circle {
      width: 18px;
      height: 18px;
      opacity: 0.8;
      border-radius: 50%;
      margin-left: 5px;
      background-color: #f44336;
      &.is-active {
        background-color: #4caf50;
      }
    }
  }
  .intent-table::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(179, 179, 179, 0.3);
    background-color: transparent;
  }
  .intent-table::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  .intent-table::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
}
.text-container {
  display: flex;
  width: 90%;
  margin-left: 5%;
  margin-top: 0.5em;
  padding-bottom: 1em;
}
.dropItem .text-container .v-text-field__details {
  display: none !important;
  background: red !important;
}
.icon-container {
  display: flex;
  width: 4%;
}
.dropzone {
  margin-top: 1em;
}
</style>