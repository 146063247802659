<template>
  <div>
    <div v-if="selectedInitiative.header_type == 'IMAGE'">
      <v-img 
        :src="selectedInitiative.header_text"
        class="header-image">
      </v-img>
    </div>
    <div v-if="selectedInitiative.header_type == 'DOCUMENT'" class="header-document pa-2 d-flex align-center">
        <img class="document-icon" :src="'/img/icons/pdf_icon.png'" alt="pdf-icon">
        <div class="document-text ml-3">
          <span>Document_name</span>
          <h5>50 kB <span>⋅</span> PDF</h5>
        </div>
    </div>
    <div
      v-if="selectedInitiative.header_type == 'TEXT'"
      class="header-document"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    selectedInitiative: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.header-image {
  margin-bottom: 10px; 
  border-radius: 5px;
  background-position: center;
}
.header-document {
  height: 60px;
  background-size: 200%;
  background-position: center;
  background-color: lightgrey;
  border-radius: 5px;
  .document-icon{
    max-height: 100%;
  }
  .document-text{
    span{
      font-size: 20px;
    }
    h5{
      font-size: 14px;
      font-weight: 300;
      span{
        font-weight: 800;
      }
    }
  }
}
</style>