<template>
  <div class="text--primary">
    <span class="footer-text" v-html="selectedInitiative.message"></span>
  </div>
</template>

<script>
export default {
  props: {
    selectedInitiative: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
// @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;1,300;1,400&display=swap");
// span.footer-text {
//   white-space: pre-line;
//   font-family: "Open Sans", sans-serif;
//   font-size: 18px;
//   font-weight: 400;
//   i{
//     font-weight: 300;
//   }
// }
</style>