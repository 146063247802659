<template>
  <div>
    <div
      v-for="(item, index) in buttons"
      :key="index"
      class="d-flex align-center"
    >
      <h3
        class="flex-grow-0 flex-shrink-1 mr-1"
        :class="item.label.length > 0 ? 'green--text' : ''"
      >
        Botão {{ Number(index) + 1 }}
      </h3>
      <v-checkbox disabled :value="item.label.length > 0"></v-checkbox>
      <v-text-field
        :maxlength="20"
        counter="20"
        class="flex-shrink-0 mr-1"
        v-model="item.label"
        label="Título"
        required
      ></v-text-field>
      <v-text-field
        class="flex-shrink-0 ml-1"
        v-model="item.value"
        label="Valor"
        required
      ></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initiative: {
      type: Object,
    },
  },
  data() {
    return {
      buttons: [
        {
          label: "",
          value: "",
        },
        {
          label: "",
          value: "",
        },
        {
          label: "",
          value: "",
        },
      ]
    }
  },
  mounted () {
    if(this.initiative.buttons){
      this.buttons = [...this.initiative.buttons]
    }
  },
};
</script>

<style lang="scss" scoped>
</style>