<template>
  <v-layout class="channel-list" column justify-center>
    <DodoTopToolBar
      :back_action="'/customerService2/channel/list'"
      :back_title="currentService.name"
      :back_type="currentService.type"
      :selected_view="2"
      :isSearchable="true"
      :searchFunction="delaySearchInitiative"
      :isFilterable="false"
    >
      <!-- :selected_view="2" -->
      <template v-slot:deactivator>
        <v-layout class="align-center justify-center mt-6 mr-5">
          <v-container class="d-flex flex-row-reverse align-center" fluid>
            <v-switch
              @change="filterInitiatives"
              v-model="activeSwitch"
              label="Mostrar Inativos"
            ></v-switch>
          </v-container>
        </v-layout>
      </template>
    </DodoTopToolBar>

    <v-card elevation="0">
      <v-data-table
        :headers="headers"
        :items="filteredInitiatives"
        :loading="loading"
        class="initiative-list secondary-background"
      >
        <template v-slot:loading>
          <v-skeleton-loader type="table-row-divider@10"></v-skeleton-loader>
        </template>
        <template v-slot:[`item.vars`]="{ item }">
          <v-chip
            v-for="var_item in item.vars"
            :key="var_item"
            small
            outlined
            class="ml-1"
            color="teal"
          >
            {{ var_item }}
          </v-chip>
        </template>
        <template v-slot:[`item.key`]="{ item }">
          {{ item.key }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" color="teal accent-4" outlined dark small>
                <span> v.{{ item.version }} </span>
              </v-chip>
            </template>
            <span>Versão em uso</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.message`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <td
                @click="openPreview(item)"
                v-bind="attrs"
                v-on="on"
                style="cursor: pointer"
              >
                {{ item.message }}
              </td>
            </template>
            <span>Clique para visualizar</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="inline-btn"
            color="orange darken-2"
            text
            v-on:click="goToInitiativesDispatch(item)"
          >
            {{ item.initiatives_dispatch_length | formatNumber }}
            disparadas</v-btn
          >
        </template>
        <template v-slot:[`item.menu`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list class="tertiary-background">
              <v-list-item @click="openNewInitiative(item)">
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.active == true"
                @click="deactivateSelected(item)"
              >
                <v-list-item-title>Desativar</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.active == false"
                @click="deactivateSelected(item)"
              >
                <v-list-item-title>Ativar</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <NewInitiative ref="NewInitiative" @tableEdited="tableEdited()">
    </NewInitiative>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          style="bottom: 10px"
          absolute
          dark
          fab
          bottom
          right
          color="teal lighten-1"
          v-bind="attrs"
          v-on="on"
          @click="openNewInitiative({'used_only_by_users': false, service_id: currentService.id})"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>Nova Iniciativa</span>
    </v-tooltip>
    <InitiativePreview @closePreview="closePreview()" ref="InitiativePreview">
    </InitiativePreview>
  </v-layout>
</template>

<script>
import NewInitiative from "./NewInitiative";
import InitiativePreview from "./Preview/Preview";
import DodoTopToolBar from "../components/TopToolBar";
import { mapMutations, mapActions, mapGetters } from "vuex";
import lodash from "lodash";

export default {
  components: {
    InitiativePreview,
    NewInitiative,
    DodoTopToolBar,
  },
  data() {
    return {
      items: [],
      filteredInitiatives: [],
      activeSwitch: false,
      header_title: "",
      back_type: null,
      headers: [
        { text: "Nome", value: "name" },
        { text: "Chave", value: "key", width: "20%" },
        { text: "Mensagem", value: "message" },
        { text: "Variáveis", value: "vars" },
        { text: "", value: "actions", align: "cemter", sortable: false },
        { text: "", value: "menu", align: "right", sortable: false },
      ],
    };
  },
  async mounted() {
    let queryParams = this.$route.params;

    await this.getInitiatives();
    this.filterInitiatives();
    if (this.$route.query.showPreview) this.openInitiativePreview();
  },
  computed: {
    ...mapGetters("rasaInitiative", {
      initiatives: "list",
      loading: "loading",
    }),
    ...mapGetters("rasaConversation", {
      currentConversation: "currentConversation",
    }),
    ...mapGetters("rasaServices", {
      currentService: "currentService",
    }),
  },
  methods: {
    ...mapMutations("rasaInitiative", {
      setSelectedInitiative: "setSelected",
      clearSelectedInitiative: "clearSelected",
    }),
    ...mapActions("rasaInitiative", {
      getInitiatives: "getAll",
      searchInitiatives: "search",
      deactivateSelectedInitiative: "deactivateSelected",
    }),
    openPreview(initiative) {
      let query = {... this.$route.query}
      query.showPreview = initiative.key
      this.$router.replace({ query })
      this.$refs.InitiativePreview.previewDialog = true;
      this.$refs.InitiativePreview.selectedInitiative = { ...initiative };
    },
    closePreview() {
      if (this.$refs.InitiativePreview.previewDialog == false) {
        let query = {... this.$route.query}
        delete query.showPreview
        this.$router.replace({ query })
      }
    },
    goToInitiativesDispatch(initiative) {
      this.setSelectedInitiative(initiative);
      this.$router.push({
        path: `/customerService2/channel/${this.currentService.id}/conversations/list?service_id=${this.currentService.id}&order=-created_at&initiative_id=${initiative.id}&without_initiatives=false`,
        params: this.$route.params,
        query: {
          page: 1,
          rowsPerPage: 25,
        },
      });
    },
    openNewInitiative(initiative) {
      this.$refs.NewInitiative.initiative = { ...initiative };
      this.$refs.NewInitiative.initiatives = [...this.initiatives];
      this.$refs.NewInitiative.template = initiative.template;
      this.$refs.NewInitiative.used_only_by_user = initiative.used_only_by_user;
      this.$refs.NewInitiative.service_id = this.$route.params.service_id;
      this.$refs.NewInitiative.dialog = true;
      if (initiative.id) {
        this.$refs.NewInitiative.originalKey = initiative.key;
      }
    },
    async tableEdited() {
      await this.getInitiatives();
      this.filterInitiatives();
    },
    async deactivateSelected(agent) {
      agent.active == false ? (agent.active = true) : (agent.active = false);
      await this.deactivateSelectedInitiative(agent);
      this.filterInitiatives();
    },
    filterInitiatives() {
      this.filteredInitiatives = this.initiatives.filter(
        (arr) => arr.active == !this.activeSwitch && arr.service_id == this.$route.params.service_id
      );
    },
    delaySearchInitiative: lodash.debounce(function (search) {
      this.searchInitiatives(search);
    }, 600),
    openInitiativePreview() {
      let initiativeToOpen = this.filteredInitiatives.find(
        (initiative) => initiative.key == this.$route.query.showPreview
      );
      this.openPreview(initiativeToOpen);
    },
  },
  watch: {
    initiatives() {
      this.filterInitiatives();
    },
  },
};
</script>
<style lang="scss" scoped>
</style>