<template>
  <div>
    <p>{{ selectedInitiative.footer_text }}</p>
    <div class="time-container">
      <span>{{ new Date().getHours() + ":" + new Date().getMinutes() }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedInitiative: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 16px;
  color: rgb(158, 158, 158);
  position: relative;
  margin: 0;
  top: 0px;
}
.time-container {
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
  // span {
  //   font-family: "Open Sans", sans-serif;
  //   font-style: italic;
  //   font-size: small;
  // }
}
</style>